<!--综合测评选项-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">综合测评审批</div>
    <!--=====================================工具栏========================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="getDataPageList"
      >查询</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="detailInfo"
        v-if="hasPerms('sy_sp')"
      >审批</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'"
            class="sm_search_title"
          ></div>
          <el-input
            size="mini"
            v-model="xh"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'"
            class="sm_search_title"
          ></div>
          <el-input
            size="mini"
            v-model="xm"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期'"
            class="sm_search_title"
          ></div>
          <el-select
            v-model="xq"
            size="small"
            clearable
            style="width: 178px"
          >
            <el-option
              v-for="(item, index) in xqlist"
              :key="index"
              :label="item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'"
              :value="item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别'"
            class="sm_search_title"
          ></div>
          <el-select
            v-model="lb"
            size="small"
            clearable
            style="width: 200px"
          >
            <el-option
              label="德育素质"
              :value="1"
            ></el-option>
            <el-option
              label="智育素质"
              :value="2"
            ></el-option>
            <el-option
              label="体育素质"
              :value="3"
            ></el-option>
            <el-option
              label="美育素质"
              :value="4"
            ></el-option>
            <el-option
              label="劳动素质"
              :value="5"
            ></el-option>
            <el-option
              label="能力素质"
              :value="6"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        stripe
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="lb"
          label="类别"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lb === 1">德育素质</el-tag>
            <el-tag v-if="scope.row.lb === 2">智育素质</el-tag>
            <el-tag v-if="scope.row.lb === 3">体育素质</el-tag>
            <el-tag v-if="scope.row.lb === 4">美育素质</el-tag>
            <el-tag v-if="scope.row.lb === 5">劳动素质</el-tag>
            <el-tag v-if="scope.row.lb === 6">能力素质</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="lx"
          label="类型"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lx === 1">加分</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.lx === 2"
            >减分</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="xq"
          label="学期"
          width="200"
        > </el-table-column>
        <el-table-column
          prop="fs"
          label="分数"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="xh"
          label="学号"
        > </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
        > </el-table-column>
        <el-table-column
          prop="bmmc"
          label="院系"
        > </el-table-column>
        <el-table-column
          prop="zymc"
          label="专业"
        > </el-table-column>
        <el-table-column
          prop="bjmc"
          label="班级"
        > </el-table-column>
        <el-table-column
          prop="lx"
          label="审批状态"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待审批</el-tag>
            <el-tag
              type="success"
              v-if="scope.row.spzt === 1"
            >通过</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.zpzt === 2"
            >不通过</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="综合素质测评项目"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar
        style="height: 550px"
        class="myscrollbar"
      >
        <el-dialog
          width="30%"
          title="加分详情"
          :visible.sync="innerVisible"
          append-to-body
        >
          <el-form>
            <el-form-item
              label="评分内容及标准"
              label-width="150px"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                readonly
                v-model="innerforminfo.PFNR"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="学期"
              label-width="150px"
              prop="XQ"
            >
              <el-input
                readonly
                v-model="innerforminfo.XQ"
              > </el-input>
            </el-form-item>
            <el-form-item
              label="加分说明"
              label-width="150px"
              prop="JFSM"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                v-model="innerforminfo.JFSM"
                readonly
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="分数"
              label-width="150px"
              prop="FS"
            >
              <el-input-number
                v-model="innerforminfo.FS"
                :precision="1"
                :step="0.1"
                :max="100"
                :min="0"
                readonly
              ></el-input-number>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="附件"
                  label-width="120px"
                >
                  <div class="demo-image__preview">
                    <el-image
                      v-for="item in innerforminfo.fj"
                      :key="item.id"
                      style="width: 100px; height: 100px; margin-right: 10px"
                      :src="baseUrl + item.filePath"
                      :preview-src-list="new Array(baseUrl + item.filePath)"
                      fit="cover"
                    >
                    </el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-dialog>
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
        >
          <el-form-item
            label="评分内容及标准"
            label-width="150px"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              readonly
              v-model="forminfo.PFNR"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="学期"
            label-width="150px"
            prop="XQ"
          >
            <el-input
              readonly
              v-model="forminfo.XQ"
            > </el-input>
          </el-form-item>
          <el-form-item
            label="加分说明"
            label-width="150px"
            prop="JFSM"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              v-model="forminfo.JFSM"
              readonly
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="分数"
            label-width="150px"
            prop="FS"
          >
            <el-input
              v-model="forminfo.FS"
              readonly
            ></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item
                label="附件"
                label-width="120px"
              >
                <div class="demo-image__preview">
                  <el-image
                    v-for="item in forminfo.fj"
                    :key="item.id"
                    style="width: 100px; height: 100px; margin-right: 10px"
                    :src="baseUrl + item.filePath"
                    :preview-src-list="new Array(baseUrl + item.filePath)"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <div class="diatabletitle">当前学期该项目已审批通过的加分</div>
          </el-row>
          <el-row>
            <el-table
              :data="diatabledata"
              border
              style="width: 100%"
              stripe
              header-cell-class-name="tableStyle"
            >
              <el-table-column
                prop="xq"
                label="学期"
              > </el-table-column>
              <el-table-column
                prop="fs"
                label="分数"
              > </el-table-column>
              <el-table-column
                prop="jfsm"
                label="加分说明"
              > </el-table-column>
              <el-table-column
                label="操作"
                width="80"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-row
            :gutter="20"
            style="margin-top: 30px"
          >
            <el-col :span="24">
              <el-form-item
                label="审批意见"
                label-width="150px"
              >
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  v-model="innerforminfo.SPYJ"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="审批"
                label-width="120px"
                prop="shzt"
              >
                <el-radio
                  v-model="info.shzt"
                  :label="1"
                >审核通过</el-radio>
                <el-radio
                  v-model="info.shzt"
                  :label="2"
                >审核打回</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { baseUrl } from '../../utils/http'
import {
  GetDSPPageList,
  GetZHCPFormData,
  saveSPInfo,
  getJFSQList,
} from '../../api/zhcp'
import { getAuthorizeButtonColumnList, getXQList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      baseUrl: baseUrl,
      windowHeight: document.documentElement.clientHeight - 50, // 实时屏幕高度
      keyword: '',
      xh: '',
      xm: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      lx: 1,
      lb: '',
      xq: '',
      xqlist: [],
      forminfo: {
        ID: '',
        XID: '',
        JFSM: '',
        FS: 0,
        SPYJ: '',
        FJIDS: [],
      },
      PFNR: '',
      spzt: null,
      info: {},
      dialogFormVisible: false,
      diatabledata: [],
      innerVisible: false,
      innerforminfo: {
        ID: '',
        XID: '',
        JFSM: '',
        FS: 0,
        FJIDS: [],
      },
    }
  },
  created() {
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getXQList().then((res) => {
      if (res.code === 200) {
        this.xqlist = res.data
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetDSPPageList({
        queryJson: JSON.stringify({
          lb: this.lb,
          lx: 1,
          xq: this.xq,
          keyword: this.keyword,
          xh: this.xh,
          xm: this.xm,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getJFSQList({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.diatabledata = res.data
          }
        })
        GetZHCPFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.PFNR = res.data.result.pfnr
            this.forminfo.XQ = res.data.result.xq
            this.forminfo.JFSM = res.data.result.jfsm
            this.forminfo.FS = res.data.result.fs
            this.forminfo.fj = res.data.fj
            console.log(this.baseUrl)
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          if (this.info.shzt !== 1 && this.info.shzt !== 2) {
            this.$message.error('请选择审批状态！')
            return false
          }
          var spinfo = {
            ID: this.keyValue,
            SPZT: this.info.shzt,
          }
          saveSPInfo(spinfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    handleClick(row) {
      GetZHCPFormData({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.innerVisible = true
          this.innerforminfo.PFNR = res.data.result.pfnr
          this.innerforminfo.XQ = res.data.result.xq
          this.innerforminfo.JFSM = res.data.result.jfsm
          this.innerforminfo.FS = res.data.result.fs
          this.innerforminfo.fj = res.data.fj
          console.log(this.baseUrl)
          this.dialogFormVisible = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
.diatabletitle {
  text-align: center;
  margin: 20px 0 10px 0;
  font-size: 16px;
  font-weight: 700;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
